@import 'spinner';

.uic-spinner-overlay {
    * {
        box-sizing:border-box;
        font-size:1em;
        margin:0;
        padding:0;
    }

    .uic-dimmer {
        background-color:$spinner-overlay-dimmer-background-color;

        .uic-spinner {
            color:$spinner-overlay-dimmer-font-color;

            @include uicSpinner(
                $spinner-overlay-diameter,
                $spinner-overlay-border-width,
                $spinner-overlay-circle-color,
                $spinner-overlay-color
            );
        }
    }

    .uic-dimmer {
        position:fixed;
        width:100%;
        height:100%;
        top:0;
        left:0;
        z-index:99998;

        .uic-spinner {
            display:block;
            position:relative;
            text-align:center;
            top:30%;
            width:auto;
            height:auto;
            padding-top:2.5em;
            user-select: none;
            cursor:default;
        }
    }

    &[data-uic-display-type="absolute"] {
        .uic-dimmer {
            position:absolute;
        }
    }
}