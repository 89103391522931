*[data-scroll-target] {
    cursor:pointer;
}

@each $breakpoint in $breakpoint-classes {
    @include breakpoint($breakpoint) {
        .column.#{$breakpoint}-shrink {
            flex: 0 0 auto;
            max-width: 100%;
        }
    }
}

.reveal .close-button-x {
    position:absolute;
    top:1rem;
    right:1rem;
    font-size:3rem;
    cursor:pointer;
}