section.intro {
    margin-top:2rem;
    font-style:italic;

    h2 {
        font-style:italic;
    }

    img {
        max-width:100%;
    }

    >.row>.column {
        text-align:center;
    }

    @include breakpoint(medium) {
        >.row>.column {
            text-align:left;

            &:nth-of-type(1) {
                order:2;
            }

            &:nth-of-type(2) {
                order:1;
            }
        }
    }
}