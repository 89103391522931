section.products {
    margin-top:4.5rem;

    .product-column {
        color:$dark-gray;
        font-size:0.75rem;
        margin-top:4.5rem;


        &:nth-of-type(1) {
            margin-top:0;
        }

        >.row,
        >.row>.column {
            height:100%;
        }

        @include breakpoint(large) {
            margin-top:0;

            &:nth-of-type(1) {
                margin-top:0;
                padding-right:1.8rem;
            }

            &:nth-of-type(2) {
                padding-left:0.9rem;
                padding-right:0.9rem;
            }

            &:nth-of-type(3) {
                padding-left:1.8rem;
            }
        }
    }

    h2 {
        margin-bottom:1.5rem;
        color:$nussetta-red;
        font-style:italic;

        @include breakpoint(medium) {
            margin-bottom:3.5rem;
        }
    }

    .product-inner {
        background-color:$light-gray;
        overflow:hidden;
        height:100%;
        width:100%;
    }

    .product-head {
        background-color:#fff;
    }

    .product-icon {
        max-width:100px;
    }

    h4 {
        color:$nussetta-red;
        line-height:1.2em;
        margin-top:2rem;
        margin-bottom:0;

        span {
            font-weight:bold;
            text-transform:uppercase;
        }
    }

    .product-image {
        margin-top:1.75rem;
        border-top-left-radius:$global-radius;
        border-top-right-radius:$global-radius;
        background-size:100%;
        background-repeat:no-repeat;
        background-position:center center;
        background-color:transparent;
        transition:background-size 650ms ease-out;

        img {
            visibility:hidden;
        }
    }

    .product-body {
        padding:1.25rem 1rem 0.5rem;
        text-align:justify;
        font-family:Arial, sans-serif;

        @include breakpoint(medium) {
            padding:1.5rem 1.66rem 0.5rem;
        }

        p {
            line-height:1rem;
        }
    }

    .product-column:nth-of-type(1) {
        .product-image {
            background-image:url(../images/walnuts.jpg);
        }
    }

    .product-column:nth-of-type(2) {
        .product-image {
            background-image:url(../images/hazelnuts.jpg);
        }
    }

    .product-column:nth-of-type(3) {
        .product-image {
            background-image:url(../images/pistachios.jpg);
        }
    }

    .product-column.column:hover {
        .product-image {
            background-size:110%;
        }
    }

    .bar {
        background-color:$nussetta-red;
        border-radius:$global-radius;
        padding:0.5rem 1.5rem;
        margin-top:1rem;

        h4 {
            color:#fff;
            margin:0;

            i {
                display:block;
                margin-top:1rem;
                font-size:2rem;
                vertical-align:middle;
                margin-left:1rem;

                @include breakpoint(large) {
                    display:inline-block;
                    margin-top:0;
                }
            }
        }
    }
}