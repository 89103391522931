footer {
    margin-top:3.5rem;
    font-size:0.95rem;

    @include breakpoint(medium) {
        margin-top:6.5rem;
    }

    .dashed-frame-container {
        position:relative;
        background-color:$nussetta-green;
        border-top-left-radius:$global-radius;
        border-top-right-radius:$global-radius;
        padding:0.6rem 0.6rem 0;
        z-index:2;
        overflow:hidden;
        margin-bottom:0;

        .dashed-frame {
            width:100%;
            height:100%;
            padding:1.5rem 1rem 2rem;
            position:relative;
            color:#fff;
            border-top:3px dashed #fff;
            border-left:3px dashed #fff;
            border-right:3px dashed #fff;
            border-top-left-radius:$global-radius;
            border-top-right-radius:$global-radius;

            @include breakpoint(medium) {
                padding:2rem 2.5rem 0.65rem;
            }
        }
    }

    a {
        color:#fff;
    }

    h2 {
        margin-top:1.5rem;
        margin-bottom:4rem;
    }

    .column-menu {
        padding-right:5%;
    }

    .column-address {
        margin-bottom:1.35rem;

        @include breakpoint(large) {
            padding-left:5%;
            padding-right:5%;
            border-left:2px dashed #fff;
            border-right:2px dashed #fff;
        }
    }

    .column-disclaimer {
        font-size:0.8rem;

        @include breakpoint(large) {
            padding-left:5%;
        }

        p {
            line-height:1;
            margin-bottom:1rem;
        }

        i {
            font-size:1.15rem;
        }
    }

    .copy-info {
        margin-top:0.5rem;

        @include breakpoint(medium) {
            margin-top:0;
            text-align:right;
        }
    }
}