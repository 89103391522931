header .banner {
    background:url(../images/header-background-small.jpg) no-repeat center center transparent;
    background-size:cover;
    height:418px;
    position:relative;
    border-bottom-left-radius:$global-radius;
    border-bottom-right-radius:$global-radius;
    overflow:hidden;
    margin-top:-2rem;

    @include breakpoint(medium) {
        background:url(../images/header-background-large.jpg) no-repeat center center transparent;
        height:500px;
    }

    .company-logo {
        margin-top:3rem;

        @include breakpoint(medium) {
            margin-top:6.5rem;
        }
    }

    h1 {
        display:inline-block;
        padding:0.15rem 1rem;
        text-transform:uppercase;
        color:$nussetta-green;
        font-size:1rem;
        font-weight:bold;
        background:rgba(255, 255, 255, 0.65);

        @include breakpoint(medium) {
            font-size:1.35rem;
            display:block;
            padding:0;
            background:transparent;
        }
    }

    .dashed-frame-container {
        width:100%;
        height:100%;
        top:0;
        padding:0.6rem;
        position:absolute;
        z-index:2;
        overflow:hidden;

        .dashed-frame {
            width:100%;
            height:100%;
            position:relative;
            border:3px dashed #fff;
            border-radius:$global-radius;
            background:rgba(255, 255, 255, 0.1);

            @include breakpoint(medium) {
                background:transparent;
            }
        }
    }

    .header-nav-bg {
        position:absolute;
        z-index:1;
        bottom:0;
        width:100%;
        height:8rem;
        background-color:rgba(102, 165, 15, 0.9);

        @include breakpoint(medium) {
            height:3.5rem;
        }
    }

    .header-nav {
        position:absolute;
        bottom:0.25rem;
        padding:0 1rem 0 0.5rem;
        width:100%;
        display:flex;
        flex-wrap:wrap;

        a {
            color:#fff;
            line-height:1.1;
        }

        @include breakpoint(medium) {
            bottom:0.45rem;
            flex-wrap:nowrap;
        }

        .main-menu {
            padding:0 0.75rem;
            display:flex;
            flex:1 1 100%;
            flex-wrap:wrap;
            text-align:left;
            margin-bottom:0.35rem;

            a {
                flex:1 1 100%;
            }

            @include breakpoint(medium) {
                padding:0 0.5rem;
                flex-wrap:nowrap;

                a {
                    margin-right:1.5rem;
                    flex:0 0 auto;
                }
            }

            @include breakpoint(large) {
                padding:0 2rem;
                width:auto;
                flex:1 0 auto;
                margin-bottom:0;

                a {
                    margin-right:2rem;
                    flex:0 0 auto;
                }
            }
        }

        .secondary-menu {
            margin-top:0.25rem;
            padding:0 1rem;
            display:flex;
            flex:1 1 100%;
            text-align:left;

            @include breakpoint(medium) {
                margin-top:0;
                flex:0 0 auto;
            }
        }

        #language-selection {
            font-size:1.1rem;
            line-height:1rem;
            background-color:transparent;
            background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");
            border:0;
            outline:0;
            padding:0 1rem 0 0;
            margin:0;
            height:auto;
            color:#fff;
            box-shadow:none;

            option {
                color:#000;
            }
        }
    }
}