section.consulting {
    font-style:italic;
    margin-top:4.5rem;

    h2 {
        font-style:italic;
        margin:1rem 0;

        a {
            color:#000;
        }
    }

    @include breakpoint(large) {
        text-align:left;
        margin-top:8.5rem;
    }
}