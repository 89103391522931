section.about {
    .dashed-frame-container {
        background-color:$nussetta-green;
        border-radius:$global-radius;
        padding:0.6rem;
        z-index:2;
        overflow:hidden;

        .dashed-frame {
            width:100%;
            height:100%;
            padding:1rem;
            position:relative;
            color:#fff;
            border:3px dashed #fff;
            border-radius:$global-radius;

            @include breakpoint(medium) {
                padding:2.5rem;
            }

            .bullet {
                color:$nussetta-red;
                font-weight:bold;
            }
        }
    }

    h2 {
        margin-top:1rem;
        margin-bottom:3rem;
    }

    ul {
        display:inline-block;
        text-align:left;
        margin-left:0.5rem;
        max-width:300px;

        @include breakpoint(medium) {
            max-width:initial;
        }

        li {
            position:relative;

            &:before {
                margin-top:0.4rem;
                display:block;
                position:absolute;
                left:-0.85rem;
                content:'';
                background-color:$nussetta-red;
                border-radius:2rem;
                width:0.44rem;
                height:0.44rem;
            }
        }
    }

    .fa-arrow-circle-down {
        font-size:2.5rem;
        vertical-align:middle;
        margin-top:1.35rem;
        margin-left:1rem;
    }
}