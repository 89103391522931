@font-face {
    font-family:'HelveticaNeueLTStd-Th';
    src:url('/projects/website/dist/fonts/HelveticaNeueLTStd-Th.eot'); /* IE9 Compat Modes */
    src:
        url('/projects/website/dist/fonts/HelveticaNeueLTStd-Th.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/projects/website/dist/fonts/HelveticaNeueLTStd-Th.otf') format('opentype'), /* Open Type Font */
        url('/projects/website/dist/fonts/HelveticaNeueLTStd-Th.svg') format('svg'), /* Legacy iOS */
        url('/projects/website/dist/fonts/HelveticaNeueLTStd-Th.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/projects/website/dist/fonts/HelveticaNeueLTStd-Th.woff') format('woff'), /* Modern Browsers */
        url('/projects/website/dist/fonts/HelveticaNeueLTStd-Th.woff2') format('woff2'); /* Modern Browsers */
    font-weight:normal;
    font-style:normal;
}