section.imprint {
    margin-top:4rem;

    @include breakpoint(large) {
        margin-top:8rem;
    }

    h2 {
        color:$nussetta-red;
        font-style:italic;
        margin-bottom:2rem;
        line-height:1;

        @include breakpoint(medium) {
            margin-right:-320px;
        }

        @include breakpoint(large) {
            margin-bottom:4rem;
        }
    }
}