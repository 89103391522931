section.certificates {
    margin-top:4rem;

    @include breakpoint(medium) {
        margin-top:8rem;
    }

    h2 {
        color:$nussetta-red;
        font-style:italic;
        margin-bottom:2rem;
        line-height:1;

        @include breakpoint(medium) {
            margin-right:-320px;
            margin-bottom:4rem;
        }
    }

    .certificate-row {
        .column {
            margin-top:1.5rem;
        }
    }

    .download-certificate-row {
        margin-top:1.5rem;
        padding-top:1rem;
        border-top:1px dotted $nussetta-green;
    }
}