section.contact {
    .dashed-frame-container {
        background-color:$nussetta-red;
        border-radius:$global-radius;
        padding:0.25rem;
        z-index:2;
        overflow:hidden;

        @include breakpoint(medium) {
            padding:0.6rem;
        }

        .dashed-frame {
            width:100%;
            height:100%;
            position:relative;
            color:#fff;
            border-radius:$global-radius;

            @include breakpoint(medium) {
                border:3px dashed #fff;
                padding:0.5rem;
            }

            @include breakpoint(large) {
                padding:4rem;
            }

            >.row>.column:nth-of-type(2) {
                margin-top:7.5rem;

                @include breakpoint(large) {
                    margin-top:0;
                }
            }
        }
    }

    h2 {
        margin-bottom:6.5rem;

        @include breakpoint(large) {
            margin-bottom:8.5rem;
        }
    }

    .sticker-card {
        position:relative;
        height:100%;
        color:#000;
        background-color:#fff;
        padding:0.4rem;
        border-radius:$global-radius;

        > div {
            height:100%;
            border:5px dotted $nussetta-green;
            border-radius:$global-radius;
            padding:0.65rem;

            @include breakpoint(medium) {
                padding:1.5rem;
            }

            .at-icon {
                font-weight:bold;
                display:inline-block;
                transform:rotate(-45deg);
                width:1.5rem;
                text-align:center;
            }

            .fa {
                width:1.5rem;
                text-align:center;
            }
        }
    }

    .icon-phone {
        position:absolute;
        z-index:3;
        height:150px;
        top:-3.5rem;
        left:3.5rem;
        transform:rotate(0);

        @include breakpoint(medium) {
            height:150px;
            top:-4.5rem;
            left:1.5rem;

            &.animate {
                animation-name:shakePhoneIcon;
                animation-duration:350ms;
            }
        }
    }

    .icon-message {
        position:absolute;
        z-index:99999;
        height:165px;
        top:-5.5rem;
        right:3rem;
        transform:rotate(15deg);
        transform-origin:10% 100%;

        @include breakpoint(medium) {
            &.animate {
                animation-name:enlargeMessageIcon;
                animation-duration:500ms;
            }
        }
    }

    .address-card {
        > div {
            padding-top:3.5rem;
        }

        a {
            color:#000;
        }
    }

    .form-card {
        @include breakpoint(large) {
            margin-left:1.5rem;
        }

        > div {
            padding-top:3rem;

            @include breakpoint(large) {
                padding-top:1.5rem;
            }
        }

        h3 {
            font-style:italic;
            font-weight:bold;
            margin-bottom:1.5rem;
        }

        input {
            margin-bottom:0.35rem;
        }

        input[name="user-email"] {
            margin-bottom:0;
        }

        textarea[name="user-message"] {
            height:100%;
            margin-top:0.35rem;

            @include breakpoint(xlarge) {
                margin-top:0;
            }
        }

        button {
            margin-top:0.7rem;
            width:100%;
            text-transform:uppercase;
            font-weight:bold;
            outline:0;

            @include breakpoint(xlarge) {
                margin-top:0.35rem;
            }
        }
    }

    #contact-form {
        position:relative;
        z-index:2;

        .form-column {
            padding-left:0.25rem;
            padding-right:0.25rem;
        }
    }

    #uic-sending-overlay {
        display:none;
    }

    #contact-success-message {
        display:none;
    }
}

@keyframes shakePhoneIcon {
    0% {

        top:-4.5rem;
        left:1.5rem;
        transform:rotate(-8deg);
    }

    14% {
        top:-4.25rem;
        left:1.1rem;
    }

    28% {
        top:-4.75rem;
        left:1.9rem;
        transform:rotate(8deg);
    }

    42% {
        top:-4.25rem;
        left:1.2rem;
    }

    56% {
        top:-4.75rem;
        left:1.8rem;
    }

    70% {
        top:-4.25rem;
        left:1.3rem;
        transform:rotate(-6deg);
    }

    85% {
        top:-4.75rem;
        left:1.7rem;
    }

    100% {
        top:-4.5rem;
        left:1.5rem;
        transform:rotate(0);
    }
}

@keyframes enlargeMessageIcon {
    0% {
        transform:rotate(15deg) scale(1);
    }

    40% {
        transform:rotate(15deg) scale(1.3);
    }

    100% {
        transform:rotate(15deg) scale(1);
    }
}